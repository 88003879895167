<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import {
  axiosApi
} from '@/services/axios';

export default {
  page: {
    title: "Onboarding",
    meta: [{ name: "description", content: appConfig.description }]
  },
  async created() {
    document.body.classList.add("auth-body-bg");
    Swal.fire({
      title: 'Welcome to 99subs.io!',
      html: 'Thank you for joining our team. <br /> We and are preparing your new campaign and setting up your dashboard.', // add html attribute if you want or remove
      allowOutsideClick: false,
      showConfirmButton: false,
      showCloseButton: false,
      showCancelButton: false,
      imageUrl: 'https://99subs.s3.amazonaws.com/img/logo-sm-dark.png',
      width: 600,
      padding: 100,
      willOpen: () => {
        Swal.showLoading()
      },
    });

    await axiosApi.get("/onboarding").then(() => {
      let self = this
      Swal.fire({
        icon: "success",
        title: 'We are ready!',
        html: 'Would you like a tour of your new dashboard.', // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, I want a tour',
        cancelButtonText: "Nope, I got this",
        width: 600,
        padding: 100
      }).then(async function (isConfirm) {
        self.$auth.user.onboarding = false
        await axiosApi.get("/onboarding/complete")
        if (isConfirm) {
          self.$router.push({
            name: "Artist Dashboard",
            query: {
              tourWanted: true
            }
          })
        } else {
          self.$router.push({
            name: "Artist Dashboard"
          })
        }
      });
    }).catch((err) => {
      console.error(err);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        footer: 'Don\'t worry, we have been notified and are working on it.'
      })
    })
  },
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
